<template>
  <v-container text-xs-center fluid>
    <v-layout row wrap style="margin: 0px">
      <v-flex xs12 style="padding: 5px">
        <v-card text-xs-center elevation="0">
          <v-layout row wrap style="padding: 4%;">
            <v-flex xs12 md12 sm12 d-flex justify-center>
              <v-avatar size="90">
                <img :src="computeLogo" alt="GoDial" />
              </v-avatar>
            </v-flex>

            <v-flex xs12 md12 sm12 d-flex justify-center style="padding: 2%;">
              GoDial Enterprise
            </v-flex>
          </v-layout>

          <v-divider></v-divider>

          <v-layout row wrap style="padding: 4%;">
            <v-flex
              xs12
              md12
              sm12
              d-flex
              justify-center
              style="padding-bottom: 4%;"
            >
              <h3>Contact</h3>
            </v-flex>

            <v-flex xs12 md4 sm4 d-flex justify-center>
              <div>
                <span class="material-icons vmiddle" style="color: #4caf50;"
                  >phone</span
                >
                <a href="tel:+19177281872">+1 9177281872</a>
              </div>
            </v-flex>
            <v-flex xs12 md4 sm4 d-flex justify-center>
              <div>
                <span class="material-icons vmiddle" style="color: #4caf50;"
                  >phone</span
                >
                <a href="tel:+917044406666"> +91 7044406666</a>
              </div>
            </v-flex>
            <v-flex xs12 md4 sm4 d-flex justify-center>
              <div>
                <span class="material-icons vmiddle" style="color: #4caf50;"
                  >email</span
                >
                <a href="mailto:support@godial.cc">support@godial.cc</a>
              </div>
            </v-flex>
          </v-layout>

          <!-- <v-layout row wrap  style="padding: 4%;">
      <v-flex xs12 md12 sm12 d-flex justify-center style="padding-bottom: 4%;">
      <h3>Chat with our experts</h3>
      </v-flex>
     
      <v-flex xs12 md12 sm12 d-flex justify-center>
          <v-btn color="primary" @click="chat"
            ><v-icon :size="24"> chat</v-icon> Chat</v-btn>
      </v-flex>
     
    </v-layout> -->

          <!-- <v-layout row wrap style="padding: 2%;">
            <v-flex
              xs12
              md12
              sm12
              d-flex
              justify-center
              style="padding-bottom: 4%;"
            >
              <h3>Watch Video</h3>
            </v-flex>

            <v-flex xs12 d-flex justify-center style="padding: 4%;">
              <youtube ref="youtube" :video-id="videoId"></youtube>
            </v-flex>
          </v-layout> -->

          <!-- <v-layout row wrap style="padding: 2%;">
            <v-flex
              xs12
              md12
              sm12
              d-flex
              justify-center
              style="padding-bottom: 2%;"
            >
              <h3>FAQ</h3>
            </v-flex>
            <v-flex xs12 md12 sm12 d-flex justify-center style="padding: 2%;">
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    >What Is GoDial?</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    GoDial is a Customer Relationship Management tool with Auto
                    call dialing and Bulk Messaging. It speeds up your calling
                    process, Saves status, Schedule callbacks, Lets you add
                    notes, Create Tasks, Rechurn and Recycle contacts and many
                    more features. Thus increasing overall productivity and
                    increase sales.
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header
                    >What Is A List?</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    A list is a set of contacts. There is a default list called
                    My List, you can add as many lists as you wish using the add
                    list option in the CRM page. List can be changed from the
                    top bar. The Dialer, CRM, Reports and Tasks pages will show
                    contacts from the selected list only.
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header
                    >How To Use The Dialer?</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    GoDial allows you to automatically place calls. Once
                    contacts are added, navigate to the Dialer page, click start
                    dialing and calls will be placed automatically, once a call
                    is completed, set the status and proceed to the next call.
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-flex>
          </v-layout> -->
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      videoId: "UKKVCPVKOws",
      videoId2: "cO1dDF1tTTY",
    };
  },
  computed: {
    player() {
      return this.$refs.youtube.player;
    },
    companyDetails() {
      return this.$store.getters.companyDetails;
    },

    computeLogo() {
      if ("picture" in this.companyDetails) {
        return `${this.$store.state.ENDPOINT}/storages/get/${this.companyDetails.picture.name}?access_token=${this.$store.getters.user.token}`;
      } else {
        return require("../assets/logo.png");
      }
    },
    computeVideo() {
      return require("../assets/browserphone.png");
    },
  },
  methods: {
    chat() {},
  },
};
</script>

<style scoped>
.vmiddle {
  vertical-align: middle !important;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active {
  text-decoration: underline;
}
</style>
